import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../models/RootState';
import {
  fetchBalance,
  logout,
  toggleDarkMode,
  setSearchGame,
  handleSearchGame,
  getCurrencyTypeFromToken,
  overwriteBalacne,
} from '../../store';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import './SubHeader.scss';
import {
  Tabs,
  Button,
  Backdrop,
  Menu,
  MenuItem,
  Divider,
  TextField,
} from '@material-ui/core';
import logo from '../../assets/images/brand/title.png';
import {
  IonButtons,
  IonImg,
  IonIcon,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonSearchbar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonModal,
} from '@ionic/react';
import themeIcon from '../../assets/images/common/theme.svg';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import Modal from '../../components/Modal/Modal';
import LoginModal from '../../components/Modal/LoginModal';
import LoginPage from '../../components/LoginForm/LoginPage';
import ChangePwdForm from '../../components/ChangePassword/ChangePassword';
import HomeView from '../../constants/HomeView';
import { ReactComponent as HomeIcon } from '../../assets/images/home/homeview/home.svg';
import { ReactComponent as VCasino } from '../../assets/images/footer/virtual-casino.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/home/homeview/avatar.svg';
import { ReactComponent as Search } from '../../assets/images/home/homeview/search.svg';
import Sun from '../../assets/images/sunny-outline.svg';
import { moonSharp, search, sunny } from 'ionicons/icons';
import { GapGame } from '../../models/dc/DcGame';
import { BRAND_NAME } from '../../constants/Branding';
import {
  EzugiChanges,
  ShowSelfRagistration,
} from '../../constants/WhitelabelConfig';
type StoreProps = {
  balance: number;
  allowedConfig: number;
  loggedIn: boolean;
  logout: () => void;
  toggleDarkMode: (val: string) => void;
  prefersDark: string;
  setSearchGame: Function;
  searchGame: string;
  handleSearchGame: () => void;
  exposure: number;
  overwriteBalacne: () => void;
  liveCasinoGames: GapGame[];
};

interface AllSubheaderProps extends StoreProps {
  subprovider: string;
}

const SubHeader: React.FC<AllSubheaderProps> = (props) => {
  const {
    allowedConfig,
    loggedIn,
    balance,
    logout,
    exposure,
    toggleDarkMode,
    prefersDark,
    setSearchGame,
    searchGame,
    handleSearchGame,
    overwriteBalacne,
    liveCasinoGames,
    subprovider,
  } = props;
  const location = useLocation();
  const checkActiveLink = (navLinkRoute) => {
    return navLinkRoute === location.pathname + location.search;
 };
  const [casinoGameTabValue, setCasinoGameCatTabValue] = useState<number>(0);
  const [refreshingBalance, setRefreshingBalance] = useState<boolean>(false);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const path = useHistory();
  const history = useHistory();
  const [tabValue, setTabValue] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const [dropdownList, setDropDownList] = useState([]);
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const tabsList = HomeView.CARDS_SLIDER_IMAGES;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setThemeHandler = () => {
    if (prefersDark === 'light') {
      localStorage.setItem('userTheme', 'dark');
      toggleDarkMode('dark');
    } else {
      localStorage.setItem('userTheme', 'light');
      toggleDarkMode('light');
    }
  };

  const loginHandler = () => {
    setModalOpen(true);
    setShowPopover({ open: false, event: undefined });
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setDropDownList(
      liveCasinoGames?.filter((i) =>
        i.gameName.toLowerCase().includes(searchGame.toLowerCase())
      )
    );
  }, [searchGame]);

  return (
    <>
      <div className="app-sub-header">
        <Tabs
          value={1}
          variant="scrollable"
          scrollButtons="auto"
          className="actions-list"
        >
          <div className="left-side">
            {/* <IonButtons className="logo-ctn">
              <IonImg
                className="logo"
                src={logo}
                onClick={() => history.push('/home')}
              />
            </IonButtons> */}
            {/* <NavLink to="/home" className="nav-link active-link">
              <div className="nav-link-btn">Home</div>
            </NavLink> */}
            {/* {tabsList.map((item, index) => (
              <div
                className={
                  casinoGameTabValue === item?.tab ? `selected card` : `card`
                }
                onClick={() => {
                  setCasinoGameCatTabValue(item?.tab);
                  setSearchGame('');
                  // setSearch('');
                  // setSearchText('');
                }}
              >
                <div className="img-ctn" key={item.name}>
                  <img
                    src={item.img}
                    className={item.class}
                    key={item.name + index}
                  />
                </div>
                <div className="label-ctn">
                  <div className={'label'}>{item.name}</div>{' '}
                </div>
              </div>
            ))} */}
            <NavLink className={`card home-link`} to={'/home'}>
              <HomeIcon height={18} width={19} />

              <div className="label-ctn">
                <div className={'label'}>Home</div>{' '}
              </div>
              <div className="border-bottom"></div>
            </NavLink>
           <NavLink
                     isActive={() => checkActiveLink('/live-casino')}
                     to={'/live-casino'}
                     className={`card`}
                   >
              <div className="live-icon">LIVE</div>
              <div className="label-ctn">
                <div className={'label'}>LIVE CASINO</div>{' '}
              </div>
              <div className="border-bottom"></div>
            </NavLink>
            {
            EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
              <>
                <NavLink className={`card`} to={'/premium_sports'}>
                  {/* <div className="live-icon">SPORTSBOOK</div> */}
                  <div className="label-ctn">
                    <div className={'label'}>SPORTSBOOK</div>{' '}
                  </div>
                  <div className="border-bottom"></div>
                </NavLink>
                <NavLink className={`card`} to={'/exchange'}>
                  {/* <div className="live-icon">SPORTSBOOK</div> */}
                  <div className="label-ctn">
                    <div className={'label'}>EXCHANGE</div>{' '}
                  </div>
                  <div className="border-bottom"></div>
                </NavLink>
              </>
            ) : null
            }
            {!EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
              <NavLink className={`card`} to={'/virtual/casino'}>
                <VCasino height={24} width={24} />
                <div className="label-ctn">
                  <div className={'label'}>VIRTUAL CASINO</div>{' '}
                </div>
                <div className="border-bottom"></div>
              </NavLink>
            ) : null}

            {!EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
              <NavLink
                to="/slot_games"
                className="card"
                // onClick={() => setModalOpen(true)}
              >
                <div className="live-icon">SLOTS</div>
                <div className="label-ctn">
                  <div className={'label'}>SLOTS</div>{' '}
                </div>
                <div className="border-bottom"></div>
              </NavLink>
            ) : null}
            <NavLink
                      to={'/live-casino?subprovider=MAC'}
                      className={`card`}
                      isActive={() => checkActiveLink('/live-casino?subprovider=MAC')}
                    >
              <div className="live-icon">MAC</div>
              <div className="label-ctn">
                <div className={'label'}>EXCITE</div>{' '}
              </div>
              <div className="border-bottom"></div>
            </NavLink>
            <NavLink
                      isActive={() => checkActiveLink('/live-casino?subprovider=crash88')}
                      to={'/live-casino?subprovider=crash88'}
                      className={`card`}
                    >
              <div className="live-icon">CRASH 88</div>
              <div className="label-ctn">
                <div className={'label'}>GAMING</div>{' '}
              </div>
              <div className="border-bottom"></div>
            </NavLink>
            {/* {
              loggedIn ?
                <NavLink
                  className={
                    `card`
                  }
                  to={'/home/'}
                >
                  <ProfileIcon height={28} width={28} />
                  <div className="label-ctn">
                    <div className={'label'}>PROFILE</div>{' '}
                  </div>
                  <div className="border-bottom"></div>
                </NavLink>
                : null
            } */}
          </div>
          <div className="right-side">
            <div className="header-actions">
              <div className="header-right-actions">
                <div className="theme-toggle">
                  <div className="theme-text">Theme</div>
                  <IonIcon
                    className={prefersDark == 'dark' ? 'sunny' : 'moon'}
                    icon={prefersDark == 'dark' ? sunny : moonSharp}
                    onClick={() => setThemeHandler()}
                  />
                </div>
                <div className="search-bar">
                  <div className="search">
                    {/* <IonSearchbar
                      animated
                      className="select-filter-control"
                      value={searchGame}
                      placeholder="Search Games"
                      autocomplete="on"
                      // interface="popover"
                      // clearIcon="true"
                      onIonChange={(e: any) => {

                        setSearchGame(e.target.value)
                        if (e.target.value.length > 2) setShowModal(true)
                        else setShowModal(false)
                      }}
                    >
                    </IonSearchbar> */}
                    <input
                      className="input"
                      placeholder="Search Games"
                      name="searchText"
                      type="text"
                      value={searchGame}
                      onChange={(e: any) => {
                        setSearchGame(e.target.value);
                        if (e.target.value.length > 2) setShowModal(true);
                        else setShowModal(false);
                      }}
                    />
                    {showModal ? (
                      <div className="ion-padding ion-live-casino">
                        {dropdownList.map((search) => (
                          <div
                            className="ion-live-card"
                            onClick={() => {
                              setShowModal(false);
                              setSearchGame(search.gameName);
                              handleSearchGame();
                            }}
                          >
                            {search.gameName}
                          </div>
                        ))}
                      </div>
                    ) : null}

                    {/* </IonModal> */}

                    <div
                      className="search-button"
                      onClick={() => handleSearchGame()}
                    >
                      <Search />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tabs>
      </div>
      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <LoginPage
            modalCloseHandler={modalCloseHandler}
            tabActive={tabValue}
          ></LoginPage>
        </LoginModal>
      </Backdrop>

      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => {}}
          />
        </Modal>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    prefersDark: state.common.prefersDark,
    searchGame: state.indianCasino.searchGame,
    balance: state.auth.balanceSummary.balance,
    exposure: state.auth.balanceSummary.exposure,
    liveCasinoGames: state.indianCasino.liveCasinoGames,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    logout: () => dispatch(logout()),
    toggleDarkMode: (val: string) => dispatch(toggleDarkMode(val)),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
    handleSearchGame: () => dispatch(handleSearchGame()),
    overwriteBalacne: () => dispatch(overwriteBalacne()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
