import { IonRow, IonImg, IonIcon } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { FOOTER_GAME_THERAPY } from '../../constants/FooterView';
import './Footer.scss';
import { RootState } from '../../models/RootState';
import Copyright from '../Copyright/Copyright';
import './Footer.scss';
import logo from '../../assets/images/brand/title.png';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as VSports } from '../../assets/images/footer/virtual-sports.svg';
import { ReactComponent as VCasino } from '../../assets/images/footer/virtual-casino.svg';
import { ReactComponent as FunGames } from '../../assets/images/footer/fun-games.svg';
import { ReactComponent as Slots } from '../../assets/images/footer/slots.svg';
import { ReactComponent as FishShooting } from '../../assets/images/footer/fish-shooting.svg';
import { ReactComponent as LinkFooter } from '../../assets/images/footer/active-link-border.svg';
import { ReactComponent as Home } from '../../assets/images/home/homeview/home.svg';

import { Button } from '@material-ui/core';
import { BRAND_NAME } from '../../constants/Branding';
import { EzugiChanges } from '../../constants/WhitelabelConfig';

type StoreProps = {
  allowedConfig: number;
  prefersDark: string;
};

const Footer: React.FC<StoreProps> = (props) => {
  const { prefersDark } = props;
  const history = useHistory();
  const location = useLocation();

  const checkActiveLink = (navLinkRoute) => {
     return navLinkRoute === location.pathname + location.search;
  };

  return (
    <>
      {/* <div className="footer-ctn">
      
      </div> */}

      <div className="mob-footer mob-view">
        <NavLink to={'/home'} className="footer-btn live">
          <LinkFooter className="svg-top-border" />
          <div className="svg-container">
            <div className="svg">
              <Home />
            </div>
          </div>
          <div className="svg-text">HOME</div>

          <Home className="svgs home-footer-icon" />
        </NavLink>
        <NavLink
          isActive={() => checkActiveLink('/live-casino')}
          to={'/live-casino'}
          className="footer-btn live"
        >
          <LinkFooter className="svg-top-border" />
          <div className="svg-container">
            <div className="svg">LIVE</div>
          </div>
          <div className="svg-text">Live Casino</div>

          <div className="svgs">LIVE</div>
        </NavLink>

        <NavLink
          to={'/live-casino?subprovider=MAC'}
          className="footer-btn mac"
          isActive={() => checkActiveLink('/live-casino?subprovider=MAC')}
        >
          <LinkFooter className="svg-top-border" />
          <div className="svg-container">
            <div className="svg">MAC</div>
          </div>
          <div className="svg-text">MAC Excite</div>

          <div className="svgs">MAC</div>
        </NavLink>

        <NavLink
          isActive={() => checkActiveLink('/live-casino?subprovider=crash88')}
          to={'/live-casino?subprovider=crash88'}
          className="footer-btn crash"
        >
          <LinkFooter className="svg-top-border" />
          <div className="svg-container">
            <div className="svg">CRASH</div>
          </div>
          <div className="svg-text">CRASH88 Gaming</div>

          <div className="svgs">CRASH88</div>
        </NavLink>

        {EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
          <>
            <NavLink className="footer-btn live" to={'/premium_sports'}>
              <LinkFooter className="svg-top-border" />
              <div className="svg-container">
                <div className="svg">Sport</div>
              </div>
              <div className="svg-text">Sportsbook</div>

              <div className="svgs">SPORT</div>
            </NavLink>
            <NavLink className="footer-btn live" to={'/exchange'}>
              <LinkFooter className="svg-top-border" />
              <div className="svg-container">
                <div className="svg">EXCH</div>
              </div>
              <div className="svg-text">Exchange</div>

              <div className="svgs">EXCH</div>
            </NavLink>
          </>
        ) : null}

        {!EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
          <NavLink to={'/virtual/casino'} className="footer-btn virtual-casino">
            <LinkFooter className="svg-top-border" />
            <div className="svg-container">
              <div className="svg">
                <VCasino />
              </div>
            </div>
            <div className="svg-text">Virtual Casino</div>

            <VCasino className="svgs" />
          </NavLink>
        ) : null}
        {/* <NavLink to={'/slot_games'} className="footer-btn slots">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <Slots />
            </div>
          </div>
          <div className="svg-text">Slot Games</div>

          <Slots className='svgs' />
        </NavLink>
        <NavLink to={'/cards'} className="footer-btn fun-games">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <FunGames />
            </div>
          </div>
          <div className="svg-text">Fun Games</div>

          <FunGames className='svgs' />
        </NavLink>
        <NavLink to={'/providers'} className="footer-btn fish-shooting">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <FishShooting />
            </div>
          </div>
          <div className="svg-text">Fish Shooting</div>

          <FishShooting className='svgs' />
        </NavLink> */}
        {/* <NavLink to={'/virtual/sports'} className="footer-btn virtuals-sports">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <VSports />
            </div>
          </div>
          <div className="svg-text">Virtual Casino</div>

          <VSports className='svgs' />
        </NavLink> */}
      </div>
      {/* <Copyright /> */}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    prefersDark: state.common.prefersDark,
  };
};

export default connect(mapStateToProps, null)(Footer);
